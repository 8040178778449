import APP_CONST from "@/constants/AppConst";
import {
    getScholarAttedanceByClassName,
    getClassRoomService,
    markScholarAttadance,
    undoScholarAttadance,
    getAllHolidaysService
} from "@/services/attedance/attedanceService";
import store from "@/store";
import APP_UTILITIES from "@/utilities/commonFunctions";
import {
    getModule,
    Module,
    VuexModule,
    Mutation,
    Action
} from "vuex-module-decorators";

@Module({
    namespaced: true,
    name: "attedanceStore",
    store,
    dynamic: true
})

class AttedanceModule extends VuexModule {
    private scholarAttedance: any = {};
    private classRoomList: any = {};
    private markAttendanceRes: any = {};
    private undoChange:any={};
    private allHolidays: any = {};
    public checkInHeaders: any = APP_CONST.ATTENDANCE_HEADER_DESKTOP.CHECKIN
    public checkOutHeaders: any = APP_CONST.ATTENDANCE_HEADER_DESKTOP.CHECKOUT
    public weekViewTransporation: boolean = true
    public tempScholarDisplayList: any = []
    public tempCurrentDisplayList: any = []
    public checkinGearSettingsObj: any = {}
    public checkinResizeObj: any = {}
    public checkInResizableArray: any = []
    public checkinResizeObjFromStorage: any = [];
    public checkOutGearSettingsObj: any = {}
    public checkOutResizeObj: any = {}
    public checkOutResizableArray: any = [];
    public checkOutResizeObjFromStorage: any = [];
    public checkinWeekGearSettingsObj: any = {};
    public checkInWeekResizableArray: any = [];
    public checkinWeekResizeObj: any = {};
    public checkInWeekResizeObjFromStorage: any = [];
    public isTransportationFilterOpen: boolean = false;
    public isGradeFilterOpen = false;
    public attendanceSearch : any = {firstName: "", lastName: "", Transportation: "", Attendance:"", isExactMatch: false};
    public checkinSortingObj: any = {}
    public checkOutSortingObj: any = {}
    public checkinWeekSortingObj: any = {}
    public checkinSearchingObj: any = {}
    public checkOutSearchingObj: any = {}
    public checkInWeekSearchingObj: any = {}
    public searchdataCopy: any = {};
    public attendancePayload: any = {Search: "", SearchField: "", classId: 0, count: 25, endDate: "", group: 0, page: 1, programId: 0, siteId: 0, sortDir: 0, sortField: "", startDate: "", type: 1, weekDay: "", isExactMatch: false}

    get allHolidaysResponse(): object {
        return this.allHolidays;
    }
    
    get attedanceResponse(): object {
        return this.scholarAttedance;
    }

    get classRoomsRes(): object {
        return this.classRoomList;
    }
    get markAttendanceStRes(): object {
        return this.markAttendanceRes;
    }

    get undoChangeRes(): object {
        return this.undoChange;
    }

    get checkInTableHeaders(){
        return this.checkInHeaders;
    }

    get checkOutTableHeaders(){
        return this.checkOutHeaders;
    }

    get weekTransporation(){
        return this.weekViewTransporation
    }

    get tempScholarDisplay(){
        return this.tempScholarDisplayList
    }

    get tempCurrentDisplay(){
        return this.tempCurrentDisplayList
    }
    
    get checkinGearSettings(){
        return this.checkinGearSettingsObj
    }

    get checkInResizeObj(){
        return this.checkinResizeObjFromStorage
    }

    get checkOutGearSettings(){
        return this.checkOutGearSettingsObj
    }

    get checkOutResizeObject(){
        return this.checkOutResizeObjFromStorage
    }

    get checkInWeekResizeFromStorage(){
        return this.checkInWeekResizeObjFromStorage
    }

    get transportationFilter(){
        return this.isTransportationFilterOpen
    }

    get attendanceSearchData(){
        return this.attendanceSearch
    }

    get attendanceAPIPayload(){
        return this.attendancePayload;
    }

    get searchDataCopy(){
        return this.searchdataCopy;
    }
    
    @Mutation
    public mutateSearchDataCopy(data: any) {
        this.searchdataCopy = data;
    }
    
    @Mutation
    public mutateAttendancePayload(payload: any){
        this.attendancePayload = payload;
    }

    @Mutation
    public allHolidaysAfterMutate(payload: any) {
        this.allHolidays = payload;
    }

    @Action({ commit: "allHolidaysAfterMutate" })
    public getAllHolidays(payload: {siteId: number, check:boolean,programId:any}) {
        async function getAllHolidaysStore(payload: {siteId: number, check:boolean,programId:any}): Promise<Object> {
            let promise = getAllHolidaysService(payload.siteId, payload.check,payload.programId);
            let result = await promise; 

            return result as object;
        }
        return getAllHolidaysStore({siteId: payload.siteId, check: payload.check,programId:payload.programId});
    }

    @Mutation
    public attedanceAfterMutate(payload: any) {
        this.scholarAttedance = payload;
    }

    @Action({ commit: "attedanceAfterMutate" })
    public getScholarAttedance(args: any) {
        async function getAttedanceRecords(args: any): Promise<Object> {
            let promise = getScholarAttedanceByClassName(args);
            let result = await promise; 

            return result as object;
        }
        return getAttedanceRecords(args);
    }

    @Mutation
    public classRoomsAfterMutate(payload: any) {
        this.classRoomList = payload;
    }

    @Action({ commit: "classRoomsAfterMutate" })
    public getClassRooms(obj: any) {
        async function getClassRoomsRecords(obj: any): Promise<Object> {
            let promise = getClassRoomService(obj);
            let result = await promise; 
            return result as object;
        }
        return getClassRoomsRecords(obj);
    }

    @Mutation
    public markAttendanceAfterMutate(payload: any) {
        this.markAttendanceRes = payload;
    }

    @Action({ commit: "markAttendanceAfterMutate" })
    public markAttendance(obj: any) {
        async function markScholarAttadanceRecords(obj: any): Promise<Object> {
            let promise = markScholarAttadance(obj);
            let result = await promise; 
            return result as object;
        }
        return markScholarAttadanceRecords(obj);
    }

    @Mutation
    public undoAttendanceAfterMutate(payload: any) {
        this.undoChange = payload;
    }

    @Action({ commit: "undoAttendanceAfterMutate" })
    public undoAttendance(undoObj: any){
        async function undoScholarAttadanceRecords(undoObj: any): Promise<Object> {
            let promise = undoScholarAttadance(undoObj);
            let result = await promise; 
            return result as object;
        }
        return undoScholarAttadanceRecords(undoObj);
    }

    @Mutation
    mutateCheckinHeaders(headers: any){
        this.checkInHeaders = headers;
    }

    @Mutation
    mutateCheckOutHeaders(headers: any){
        this.checkOutHeaders = headers;
    }

    @Mutation
    mutateWeekTransportation(display: boolean){
        this.weekViewTransporation = display
    }

    @Mutation
    mutateScholarDisplay(scholarList: any){
        this.tempScholarDisplayList = scholarList
    }

    @Mutation
    mutateCurrentWeekrDisplay(currentWeek: any){
        this.tempCurrentDisplayList = currentWeek
    }

    @Mutation
    mutateCheckInGearIconSetting(data: any){
        let checInHeaders: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinGearSettingsData')){
            checInHeaders = localStorage.getItem('CheckinGearSettingsData');
            checInHeaders = JSON.parse(checInHeaders);
        }

        if(!Object.keys(this.checkinGearSettingsObj).length && !checInHeaders){
            this.checkinGearSettingsObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkinGearSettingsObj.hasOwnProperty(programId) && this.checkinGearSettingsObj[programId].hasOwnProperty(siteId)){
            this.checkinGearSettingsObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkinGearSettingsObj.hasOwnProperty(programId) && !this.checkinGearSettingsObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkinGearSettingsObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinGearSettingsObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          //this.checkinGearSettingsObj = Object.assign({}, this.checkinGearSettingsObj, JSON.parse(JSON.stringify(programData), checInHeaders))
          this.checkinGearSettingsObj = {...this.checkinGearSettingsObj, ...programData, ...checInHeaders}
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinGearSettingsData',this.checkinGearSettingsObj)
    }

    @Mutation
    mutateCheckinResizeObj(obj: { column: any; maxWidth: string; minWidth: string;}) {
        let checkResize: any = {};
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinResizeData')){
            checkResize = localStorage.getItem('CheckinResizeData');
            checkResize = JSON.parse(checkResize);
        }

        if(checkResize && checkResize[programId] && checkResize[programId][siteId] && !this.checkInResizableArray.length){
            this.checkInResizableArray = JSON.parse(JSON.stringify(checkResize[programId][siteId]))
        }

        let keyIndex = this.checkInResizableArray.findIndex((e: { id: any; }) => e.id == obj.column.id)
        if (keyIndex > -1) {
        this.checkInResizableArray[keyIndex] = { id : obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth}
        } else {
        this.checkInResizableArray.push({ id: obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth })
        }
        if(!Object.keys(this.checkinResizeObj).length && !checkResize){
            this.checkinResizeObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(this.checkInResizableArray))
                }
            } 
        }

        if(this.checkinResizeObj.hasOwnProperty(programId) && this.checkinResizeObj[programId].hasOwnProperty(siteId)){
            this.checkinResizeObj[programId][siteId] = JSON.parse(JSON.stringify(this.checkInResizableArray))
        }else if(this.checkinResizeObj.hasOwnProperty(programId) && !this.checkinResizeObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(this.checkInResizableArray))
            }
          Object.assign(this.checkinResizeObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinResizeObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(this.checkInResizableArray))
            }
          }
          //Object.assign(this.checkinResizeObj, JSON.parse(JSON.stringify(programData)))
          this.checkinResizeObj = {...this.checkinResizeObj, ...programData, ...checkResize}
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinResizeData',this.checkinResizeObj)
    }

    @Mutation
    mutateCheckOutGearIconSetting(data: any){
        let checkOutHeaders: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckOutGearSettingsData')){
            checkOutHeaders = localStorage.getItem('CheckOutGearSettingsData');
            checkOutHeaders = JSON.parse(checkOutHeaders);
        }

        if(!Object.keys(this.checkOutGearSettingsObj).length && !checkOutHeaders){
            this.checkOutGearSettingsObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkOutGearSettingsObj.hasOwnProperty(programId) && this.checkOutGearSettingsObj[programId].hasOwnProperty(siteId)){
            this.checkOutGearSettingsObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkOutGearSettingsObj.hasOwnProperty(programId) && !this.checkOutGearSettingsObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkOutGearSettingsObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkOutGearSettingsObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          this.checkOutGearSettingsObj = {...this.checkOutGearSettingsObj, ...programData, ...checkOutHeaders}
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckOutGearSettingsData',this.checkOutGearSettingsObj)
    }

    @Mutation
    mutateCheckOutResizeObj(obj: { column: any; maxWidth: string; minWidth: string;}) {
        let checkOutResize: any = {};
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckOutResizeData')){
            checkOutResize = localStorage.getItem('CheckOutResizeData');
            checkOutResize = JSON.parse(checkOutResize);
        }

        if(checkOutResize && checkOutResize[programId] && checkOutResize[programId][siteId] && !this.checkOutResizableArray.length){
            this.checkOutResizableArray = JSON.parse(JSON.stringify(checkOutResize[programId][siteId]))
        }

        let keyIndex = this.checkOutResizableArray.findIndex((e: { id: any; }) => e.id == obj.column.id)
        if (keyIndex > -1) {
        this.checkOutResizableArray[keyIndex] = { id : obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth}
        } else {
        this.checkOutResizableArray.push({ id: obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth })
        }
        if(!Object.keys(this.checkOutResizeObj).length && !checkOutResize){
            this.checkOutResizeObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(this.checkOutResizableArray))
                }
            } 
        }

        if(this.checkOutResizeObj.hasOwnProperty(programId) && this.checkOutResizeObj[programId].hasOwnProperty(siteId)){
            this.checkOutResizeObj[programId][siteId] = JSON.parse(JSON.stringify(this.checkOutResizableArray))
        }else if(this.checkOutResizeObj.hasOwnProperty(programId) && !this.checkOutResizeObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(this.checkOutResizableArray))
            }
          Object.assign(this.checkOutResizeObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkOutResizeObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(this.checkOutResizableArray))
            }
          }
          //Object.assign(this.checkinResizeObj, JSON.parse(JSON.stringify(programData)))
          this.checkOutResizeObj = {...this.checkOutResizeObj, ...programData, ...checkOutResize}
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckOutResizeData',this.checkOutResizeObj)
    }

    @Mutation
    mutateCheckInWeekGearIconSetting(data: any){
        let weekChecInHeaders: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinWeekGearSettingsData')){
            weekChecInHeaders = localStorage.getItem('CheckinWeekGearSettingsData');
            weekChecInHeaders = JSON.parse(weekChecInHeaders);
        }

        if(!Object.keys(this.checkinWeekGearSettingsObj).length && !weekChecInHeaders){
            this.checkinGearSettingsObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkinWeekGearSettingsObj.hasOwnProperty(programId) && this.checkinWeekGearSettingsObj[programId].hasOwnProperty(siteId)){
            this.checkinWeekGearSettingsObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkinWeekGearSettingsObj.hasOwnProperty(programId) && !this.checkinWeekGearSettingsObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkinWeekGearSettingsObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinWeekGearSettingsObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          this.checkinWeekGearSettingsObj = {...this.checkinWeekGearSettingsObj, ...programData, ...weekChecInHeaders}
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinWeekGearSettingsData',this.checkinWeekGearSettingsObj)
    }

    @Mutation
    mutateCheckinWeekResizeObj(obj: { column: any; maxWidth: string; minWidth: string;}) {
        let checkInWeekResize: any = {};
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinWeekResizeData')){
            checkInWeekResize = localStorage.getItem('CheckinWeekResizeData');
            checkInWeekResize = JSON.parse(checkInWeekResize);
        }

        if(checkInWeekResize && checkInWeekResize[programId] && checkInWeekResize[programId][siteId] && !this.checkInWeekResizableArray.length){
            this.checkInWeekResizableArray = JSON.parse(JSON.stringify(checkInWeekResize[programId][siteId]))
        }

        let keyIndex = this.checkInWeekResizableArray.findIndex((e: { id: any; }) => e.id == obj.column.id)
        if (keyIndex > -1) {
        this.checkInWeekResizableArray[keyIndex] = { id : obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth}
        } else {
        this.checkInWeekResizableArray.push({ id: obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth })
        }
        if(!Object.keys(this.checkinResizeObj).length && !checkInWeekResize){
            this.checkinWeekResizeObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(this.checkInWeekResizableArray))
                }
            } 
        }

        if(this.checkinWeekResizeObj.hasOwnProperty(programId) && this.checkinWeekResizeObj[programId].hasOwnProperty(siteId)){
            this.checkinWeekResizeObj[programId][siteId] = JSON.parse(JSON.stringify(this.checkInWeekResizableArray))
        }else if(this.checkinWeekResizeObj.hasOwnProperty(programId) && !this.checkinWeekResizeObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(this.checkInWeekResizableArray))
            }
          Object.assign(this.checkinWeekResizeObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinWeekResizeObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(this.checkInWeekResizableArray))
            }
          }
          this.checkinWeekResizeObj = {...this.checkinWeekResizeObj, ...programData, ...checkInWeekResize}
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinWeekResizeData',this.checkinWeekResizeObj)
    }

    @Mutation
    mutateCheckInSortingPersistance(data: any){
        let checInSorting: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinSortingData')){
            checInSorting = localStorage.getItem('CheckinSortingData');
            checInSorting = JSON.parse(checInSorting);
        }

        if(!Object.keys(this.checkinSortingObj).length && !checInSorting){
            this.checkinSortingObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkinSortingObj.hasOwnProperty(programId) && this.checkinSortingObj[programId].hasOwnProperty(siteId)){
            this.checkinSortingObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkinSortingObj.hasOwnProperty(programId) && !this.checkinSortingObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkinSortingObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinSortingObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          if(programId in checInSorting){
            Object.assign(checInSorting[programId], JSON.parse(JSON.stringify(programData[programId])))
            Object.assign(this.checkinSortingObj, checInSorting)
          } else{
              this.checkinSortingObj = Object.assign({}, JSON.parse(JSON.stringify(checInSorting)), JSON.parse(JSON.stringify(programData)))
          }
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinSortingData',this.checkinSortingObj)
    }

    @Mutation
    mutateCheckOutSortingPersistance(data: any){
        let checOutSorting: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckOutSortingData')){
            checOutSorting = localStorage.getItem('CheckOutSortingData');
            checOutSorting = JSON.parse(checOutSorting);
        }

        if(!Object.keys(this.checkOutSortingObj).length && !checOutSorting){
            this.checkOutSortingObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkOutSortingObj.hasOwnProperty(programId) && this.checkOutSortingObj[programId].hasOwnProperty(siteId)){
            this.checkOutSortingObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkOutSortingObj.hasOwnProperty(programId) && !this.checkOutSortingObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkOutSortingObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkOutSortingObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          if(programId in checOutSorting){
            Object.assign(checOutSorting[programId], JSON.parse(JSON.stringify(programData[programId])))
            Object.assign(this.checkOutSortingObj, checOutSorting)
          } else{
              this.checkOutSortingObj = Object.assign({}, JSON.parse(JSON.stringify(checOutSorting)), JSON.parse(JSON.stringify(programData)))
          }
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckOutSortingData',this.checkOutSortingObj)
    }

    @Mutation
    mutateCheckInWeekSortingPersistance(data: any){
        let checInWeekSorting: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinWeekSortingData')){
            checInWeekSorting = localStorage.getItem('CheckinWeekSortingData');
            checInWeekSorting = JSON.parse(checInWeekSorting);
        }

        if(!Object.keys(this.checkinWeekSortingObj).length && !checInWeekSorting){
            this.checkinWeekSortingObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkinWeekSortingObj.hasOwnProperty(programId) && this.checkinWeekSortingObj[programId].hasOwnProperty(siteId)){
            this.checkinWeekSortingObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkinWeekSortingObj.hasOwnProperty(programId) && !this.checkinWeekSortingObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkinWeekSortingObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinWeekSortingObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          if(programId in checInWeekSorting){
            Object.assign(checInWeekSorting[programId], JSON.parse(JSON.stringify(programData[programId])))
            Object.assign(this.checkinWeekSortingObj, checInWeekSorting)
          } else{
              this.checkinWeekSortingObj = Object.assign({}, JSON.parse(JSON.stringify(checInWeekSorting)), JSON.parse(JSON.stringify(programData)))
          }
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinWeekSortingData',this.checkinWeekSortingObj)
    }

    @Mutation
    mutateCheckInSearchingPersistance(data: any){
        let checInSearching: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinSearchingData')){
            checInSearching = localStorage.getItem('CheckinSearchingData');
            checInSearching = JSON.parse(checInSearching);
        }

        if(!Object.keys(this.checkinSearchingObj).length && !checInSearching){
            this.checkinSearchingObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkinSearchingObj.hasOwnProperty(programId) && this.checkinSearchingObj[programId].hasOwnProperty(siteId)){
            this.checkinSearchingObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkinSearchingObj.hasOwnProperty(programId) && !this.checkinSearchingObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkinSearchingObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkinSearchingObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          if(programId in checInSearching){
            Object.assign(checInSearching[programId], JSON.parse(JSON.stringify(programData[programId])))
            Object.assign(this.checkinSearchingObj, checInSearching)

          } else{
              this.checkinSearchingObj = Object.assign({}, JSON.parse(JSON.stringify(checInSearching)), JSON.parse(JSON.stringify(programData)))
          }
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinSearchingData',this.checkinSearchingObj)
    }

    @Mutation
    mutateCheckOutSearchingPersistance(data: any){
        let checOutSearching: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckOutSearchingData')){
            checOutSearching = localStorage.getItem('CheckOutSearchingData');
            checOutSearching = JSON.parse(checOutSearching);
        }

        if(!Object.keys(this.checkOutSearchingObj).length && !checOutSearching){
            this.checkOutSearchingObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkOutSearchingObj.hasOwnProperty(programId) && this.checkOutSearchingObj[programId].hasOwnProperty(siteId)){
            this.checkOutSearchingObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkOutSearchingObj.hasOwnProperty(programId) && !this.checkOutSearchingObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkOutSearchingObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkOutSearchingObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          if(programId in checOutSearching){
            Object.assign(checOutSearching[programId], JSON.parse(JSON.stringify(programData[programId])))
            Object.assign(this.checkOutSearchingObj, checOutSearching)

          } else{
              this.checkOutSearchingObj = Object.assign({}, JSON.parse(JSON.stringify(checOutSearching)), JSON.parse(JSON.stringify(programData)))
          }
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckOutSearchingData',this.checkOutSearchingObj)
    }

    @Mutation
    mutateCheckInWeekSearchingPersistance(data: any){
        let checInWeekSearching: any = {}
        let programId : any = APP_UTILITIES.getCookie('programId')
        programId =JSON.parse(programId)
        let siteId : any = APP_UTILITIES.getCookie('siteId')
        siteId = JSON.parse(siteId)

        if(localStorage.getItem('CheckinWeekSearchingData')){
            checInWeekSearching = localStorage.getItem('CheckinWeekSearchingData');
            checInWeekSearching = JSON.parse(checInWeekSearching);
        }

        if(!Object.keys(this.checkInWeekSearchingObj).length && !checInWeekSearching){
            this.checkInWeekSearchingObj = {
                [programId]:{
                    [siteId] : JSON.parse(JSON.stringify(data))
                }
            } 
        }

        if(this.checkInWeekSearchingObj.hasOwnProperty(programId) && this.checkInWeekSearchingObj[programId].hasOwnProperty(siteId)){
            this.checkInWeekSearchingObj[programId][siteId] = JSON.parse(JSON.stringify(data))
        }else if(this.checkInWeekSearchingObj.hasOwnProperty(programId) && !this.checkInWeekSearchingObj[programId].hasOwnProperty(siteId)) {
          let siteData : any ={
                [siteId] : JSON.parse(JSON.stringify(data))
            }
          Object.assign(this.checkInWeekSearchingObj[programId], JSON.parse(JSON.stringify(siteData)))
        }else if(!this.checkInWeekSearchingObj.hasOwnProperty(programId)) {
          let programData : any ={
            [programId]:{
              [siteId]: JSON.parse(JSON.stringify(data))
            }
          }
          if(programId in checInWeekSearching){
            Object.assign(checInWeekSearching[programId], JSON.parse(JSON.stringify(programData[programId])))
            Object.assign(this.checkInWeekSearchingObj, checInWeekSearching)

          } else{
              this.checkInWeekSearchingObj = Object.assign({}, JSON.parse(JSON.stringify(checInWeekSearching)), JSON.parse(JSON.stringify(programData)))
          }
        }
        APP_UTILITIES.setFilteredColumnsAttendace('CheckinWeekSearchingData',this.checkInWeekSearchingObj)
    }

    @Mutation
    mutateCheckInResizeObj(checkInResize: any){
        this.checkinResizeObjFromStorage = checkInResize;
    }

    @Mutation
    mutateCheckOutResizeObject(checkOutResize: any){
        this.checkOutResizeObjFromStorage = checkOutResize;
    }

    @Mutation
    mutateWeekResizeObj(checkInWeekResize: any){
        this.checkInWeekResizeObjFromStorage = checkInWeekResize;
    }

    @Mutation
    mutateTransportationFilterState(filter: boolean){
        this.isTransportationFilterOpen = filter;
    }

    @Mutation
    mutateGradeFilterState(filter: boolean){
      this.isGradeFilterOpen = filter;
    }

    @Mutation
    mutateAttendanceSearch(searchValue: any){
        this.attendanceSearch = {...this.attendanceSearch, ...searchValue}
    }
  }

export default getModule(AttedanceModule);
